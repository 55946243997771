import ciudadanias from "../img/ciudadanias.png"
import rickMorty from "../img/rick&morty.jpg"
import password from "../img/password.jpg"
import encriptador from "../img/encriptador.jpg"
import countries from "../img/countries.jpg"
import toDoAlura from "../img/toDoAlura.jpg"
import cereza from "../img/cereza.jpg"
import valomi from "../img/valomi.jpg"
import fl from "../img/FL.png"
import pf from "../img/pets.png"
import pomodoro from "../img/pomodoro.jpg"

export const projects = [
    {  
        title: "Ciudadanias Italianas",
        category: "FrontEnd",
        tipo: "Web",
        lenguaje: ["HTML", "JS"],
        frameworks: [],
        styles: ["CSS"],
        entorno: "Proyecto",
        image: ciudadanias,
        github: "https://github.com/noedenisel/partidas-ciudadania-it",
        demo: "https://partidas-ciudadania-it.vercel.app/",
        resume: "Realiza el control de las partidas de una carpeta de ciudadania italiana. Pretende democratizar el control de carpetas evitando tener que pagar a gestores. Una vez cargada toda la documentacion realiza el control automático y muestra los errores encontrados que deberían rectificarse para poder iniciar el trámite."
    },
    {    
        title: "Rick & Morty",
        category: "FrontEnd",
        tipo: "SPA",
        lenguaje: ["HTML","JS"],
        frameworks: ["React.js", "Redux"],
        styles: ["CSS"],
        entorno: "Bootcamp",
        image: rickMorty,
        github: "https://github.com/noedenisel/Rick-and-Morty",
        demo: "https://rick-and-morty-noedenisel.vercel.app/",
        resume: "Proyecto de integracion React & Redux del Bootcamp Soy Henry. Fue elegida por mis compañeros de bootcamp como una de las 3 mejores de la cohorte"
    },
    {   
        title: "Encriptador Challenge",
        category: "FrontEnd",
        tipo: "Web",
        lenguaje: ["HTML","JS"],
        frameworks: [],
        styles: ["CSS"],
        entorno: "Especializacion FrontEnd",
        image: encriptador,
        github: "https://github.com/noedenisel/EncriptadorChallenge",
        demo: "https://noedenisel.github.io/EncriptadorChallenge/",
        resume: "Web que encripta y desencripta textos utilizando llaves de encriptación. La aplicación es capaz de encriptar y desencriptar palabras, y funciona únicamente con letras minúsculas y sin acentos ni caracteres especiales. Realizada en la etapa introductoria de la especialización FrontEnd de Alura"
    },
    {   
        title: "Password Generator",
        category: "FrontEnd",
        tipo: "Web",
        lenguaje: ["HTML","JS"],
        frameworks: [],
        styles: [ "CSS"],
        entorno: "Bootcamp",
        image: password,
        github: "https://github.com/noedenisel/password-generator",
        demo: "https://noedenisel.github.io/password-generator/",
        resume: "Web generadora de passwords aleatorios, generada en el taller para grads HenryTips"
    },
    {   
        title: "Facturador",
        category: "FrontEnd",
        tipo: "SPA",
        lenguaje: ["HTML","JS"],
        frameworks: ["React.js"],
        styles: [ "Boostrap"],
        entorno: "Proyecto",
        image: cereza,  
        github: "https://github.com/noedenisel/cereza-PruebaTecnica",
        demo: "https://cereza-prueba-tecnica-pveiahx47-noedenisel.vercel.app/",
        resume: "SPA que muestra un listado de facturas y permita la creación de nuevas facturas a través de un formulario, cargando clientes y consumiendo productos y sus precios de una db."
    },
    {   
        title: "Pomodoro",
        category: "FrontEnd",
        tipo: "Web", 
        lenguaje: ["HTML", "JS"],
        frameworks: [],
        styles: [ "CSS"],
        entorno: "Proyecto",
        image: pomodoro,
        github: "https://github.com/noedenisel/pomodoro",
        demo: "https://noedenisel.github.io/pomodoro/",
        resume: "Web de pomodoro con temática de Harry Potter diseñada por mi hija, creada para ella. Se le pueden agregar distintas tareas y al iniciar cuenta intervalos de 25x5, durante el tiempo de concentración cambia el fondo al comedor de Howarts de noche. Muestra si la tarea esta en progreso o completada"
    },
    {   
        title: "Pets America",
        category: "FullStack",
        tipo: "SPA",
        lenguaje: ["JS"],
        frameworks: ["React.js", "Zuztand", "Node.js"],
        styles: ["Boostrap"],
        entorno: "Bootcamp",
        image: pf,
        github: "https://github.com/erikfille/PF-Henry",
        demo: "",
        resume: "E-commerce realizado para el proyecto final del Bootcamp Soy Henry. Entre mis responsabilidades en el equiop FrontEnd se destaca la implementacion y estilado de la paginación y pasarela de pago"
    },
    {   
        title: "Countries App",
        category: "FullStack",
        tipo: "SPA",
       lenguaje: ["JS"],
       frameworks: ["React.js", "Redux", "Node.js", "Express", "Sequelize"],
       styles:["CSS"],
        db: ["PostgresSQL"],
        entorno: "Bootcamp",
        image: countries,
     
        github: "https://github.com/noedenisel/PI-Henry-Countries",
        demo: "",
        resume: "Proyecto individual para el Bootcamp soy Henry, consistente en una SPA en la cual se pueda ver información de distintos paises utilizando la api externa restcountries y a partir de ella poder, entre otras cosas: Buscar paises, Filtrarlos / Ordenarlos y crear actividades turísticas"
    },
    {   
        title: "FL Legal Solutions",
        category: "FrontEnd",
        tipo: "Landing Web", 
        lenguaje: [],
        cms: ["Hostinger"],
        frameworks: [],
        styles: [],
        entorno: "Freelance",
        image: fl,
        
        github: "",
        demo: "https://flsolutions.com.ar/",
        resume: "Landing web para FL Legal Solutions a través de Abogada Marketinera"
    },
    {   
        title: "ToDo",
        category: "FrontEnd",
        tipo: "Web",
        lenguaje: ["JS"],
        frameworks: [],
        styles: ["CSS"],
        entorno: "Especializacion FrontEnd",
        image: toDoAlura,
       
        github: "https://github.com/noedenisel/toDo-JS-Alura",
        demo: "https://noedenisel.github.io/toDo-JS-Alura/",
        resume: "Clásica web estática de ToDo. Realizada en la etapa introductoria de la especialización FrontEnd de Alura"
    },
  
    {   
        title: "Valomi",
        category: "FrontEnd",
        tipo: "Landing Web",
        lenguaje: [],
        frameworks: [],
        styles: [],
        cms: ["Hostinger"],
        entorno: "Freelance",
        image: valomi,
        
        github: "",
        demo: "https://valomi.org/",
        resume: "Landing web estática donada a la ONG Vecinos en Alerta de Lomas del Mirador - Junio 2023."
    },

]
