

import React from 'react'

import BootcampCard from "../../components/bootcampCards/bootcampCard"
import { bootcamps } from '../../components/bootcamps'

import styles from "./education.module.css"

export default function education() {
  return (
    <div className={styles.educationContainer}>
      <h2 className={styles.educationTitle}>Formacion</h2>
      <div className={styles.bootcampCardContainer}>
        {bootcamps.map((bootcamp, index) => (
          <BootcampCard
            key={index}
            name={bootcamp.name}
            title={bootcamp.title}
            stacks={bootcamp.stacks}
            resume={bootcamp.resume}
            diploma={bootcamp.diploma}
          />
        ))}
      </div>
      </div>
  );
}

