import React from "react";
import { Route, Routes,  Navigate } from "react-router-dom";

import './App.css';
import Home from "./views/home/home";
import AboutMe from "./views/aboutMe/aboutMe";
import ContactMe from "./views/contactMe/contactMe";
import Education from "./views/education/education";
import Portfolio from "./views/projects/projects";
import NotFound from "./views/error404/notFound";
import NavBar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <div className="AppContainer">
      <Routes>
        <Route exact path = "/" element = {<Home/>}/>
        <Route path = "/projects" element = {<Portfolio/>}/>
        <Route path = "/education" element = {<Education/>}/>
        <Route path = "/about" element = {<AboutMe/>}/>
        <Route path = "/contactme" element = {<ContactMe/>}/>
        <Route path ="/Error404" element = {<NotFound/>}/>
        <Route path= "*" element={<Navigate to ="/Error404" />}/>
      </Routes>
      </div>
      
      <Footer/>
    </div>
  );
}

export default App;

