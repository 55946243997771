import React from 'react'

import poster from "../../img/NoeLombardo.jpg"


import styles from "./home.module.css"

export default function home() {
  return (
    <div className={styles.container}>
        <section className={styles.containerImagenNoe}>
            <img src={poster} alt="posterNoe" />
        </section>
        <section className={styles.infoHome}>
          <div>
             <p className={styles.textHome}>I´m</p>
            <h2 className={styles.title}>Noe Denisel</h2>
            <p className={styles.textHome}>and I'm your next FrontEnd developer</p>
          </div>
        </section>
    </div>
  )
}
