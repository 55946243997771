import React from 'react'



import linkedin from "../../img/linkedin.png"
import github from "../../img/github (1).png"

import styles from "./contactMe.module.css"

export default function contactMe() {
  return (
    <div>
      <h1 className={styles.contactTitle}>Contact Me</h1>

      <section class={styles.contacto}>
        <p>
          <a href="https://www.linkedin.com/in/noedenisel/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="link to linkedin"></img>
          </a>
        </p>
        <p>
          <a href="https://www.github.com/noedenisel/" target="_blank" rel="noopener noreferrer">
            <img src={github} alt="link to github"></img>
          </a>
        </p>
      </section>
    </div>
  )
}
