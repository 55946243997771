import React from 'react'

import styles from "./notFound.module.css"

export default function notFound() {
  return (
       <div className={styles.notFoundContainer}>
          <div>
            <h2 className={styles.notFoundTitle}>Sory...</h2>
            <h3 className={styles.notFoundSubTitle}>Page Not Found</h3>
          </div>
      </div>
  )
}
