import diploma from "../../img/diploma.png"

import styles from "./bootcampCards.module.css";

export default function Card(props) {


return (
  <div className={styles.flipCard}>
    <div className={styles.flipCardInner}>
      <div className={styles.flipCardFront}>
        <p className={styles.title}>{props.name}</p> 
        <p className={styles.subTittle}>{props.title}</p>   
        <br />
        <span>Stacks: {props.stacks.join(", ")}</span>    
        <br />
        <p>{props.resume}</p>
        <div className={styles.socialMedia}>
            {props.diploma ? (  
              <a href={props.diploma} target="_blank" rel="noopener noreferrer">
                <img src={diploma} alt="link to certification"></img>
              </a>
            ) : null }
          </div>
      </div>
     </div>
  </div>
  );
}
   
