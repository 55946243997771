import github from "../../img/github.png"
import demo from "../../img/video.png"

import styles from "./card.module.css";

export default function Card(props) {


return (

<div className={styles.flipCard}>
    <div className={styles.flipCardInner}>
      <div className={styles.flipCardFront}>
        <div className={styles.userPicture}>
          <img src={props.image} alt="" />
        </div>
        <p className={styles.title}>{props.name}</p>    
       
      </div>
      <div className={styles.flipCardBack}>
          <p className={styles.title}>{props.name}</p>
          <p>{props.resume}</p>
          <span>Stack {props.lenguaje.join(" ")} {props.frameworks.join(" ")} {props.styles.join(" ")}</span>

          <div className={styles.socialMedia}>
            {props.github ? (  
              <a href={props.github} target="_blank" rel="noopener noreferrer">
                <img src={github} alt="icon github"></img>
              </a>
            ) : null }

            {props.demo ? ( 
              <a href={props.demo} target="_blank" rel="noopener noreferrer">
                <img src={demo} alt="demo"></img>
              </a>
            ) : null }           
          </div>
      </div>
     </div>
  </div>

  );
}
   
