// ToDo : agregar todos los diplomas de alura de alguna manera

export const bootcamps = [
    {
        name: "Codo a Codo",
        title: "Diseño UX/UI",
        stacks: ["Figma", "Ilustrator", "Photoshop", "Android", "Whimsical", "Optimal Workshop"],
        resume: "Curso destinado a aprender a diseñar interfaces de usuario de aplicaciones móviles utilizando los principios de diseño UX/UI y recorriendo todo el proceso de diseño,  desde la investigación, la creación de una idea innovadora, el desarrollo de un prototipo funcional, hasta las pruebas con usuarios reales. Enseña sobre principios de diseño visual y cómo aplicarlos para crear diseños coherentes  y atractivos, así como a utilizar herramientas profesionales. Modalidad: clases en vivo obligatorias 2 veces por semana. Duracion 20 semanas. Carga horaria 198 hs.",
        diploma: ""
    },
    {
        name: "Alura",
        title: "FrontEnd Developer",
        stacks: ["HTML", "CSS", "JS", "React"],
        resume: "Especialización FrontEnd en Alura-Latam G5",
        diploma: ""
    },
    {
        name: "Henry",
        title: "FullStack Developer",
        stacks: ["HTML", "CSS", "JS", "React", "Redux", "Node.js"],
        resume: "Bootacmp de 8 meses de duración. Egreso: Mayo 2023. Programa de estudios: Introduccioón a CSS - JavaScript Avanzado I y II - Estructura de datos I, II y III - Algoritmos I y II - Dom Avanzado -  CSS avanzado - ES6 selectores - AJAX - Módulos y Bundlers - React - Redux - Node Js - Promises - Web Server - Advance Promises - Express - Testing - DBMS - SQL - ORM - Autenticación ",
        diploma: "https://certificates.soyhenry.com/new-cert?id=e91de5559ed4d84d48a3df0b0961bfc094f4967b29b86e8b464a11b963c761ba"
    },
    {
        name: "OpenBootcamp",
        title: "Frontend Developer",
        stacks: ["HTML", "CSS", "JS", "React"],
        resume: "",
        diploma: ""
    },
    {
        name: "Samsung DesArrolladoras",
        title: "FullStack Developer",
        stacks: ["HTML", "CSS", "JS"],
        resume: "Impartido por la platforma BeJob del grupo Santillana. Samsung DesArrolladoras es un programa diseñado con la finalidad de formar a mujeres en el desarrollo web para así acortar la brecha de género que existe en el sector. El programa consta de tres niveles: Nivel 1:Instroducción al desarrollo web, con una carga horaria de 140 hs. A este nivel accedieron 1000 inscriptas elegidas por su perfil luego de aplicar a un proceso de selección. Nivel 2: esarrollo Front End, con una carga horaria de 140 hs. Luego de cursar y aprobar el nivel 1, las 700 mejores cursantes accedieron al Nivel 2. Nivel 3: Desarrollo FullStack. El mismo tuvo una carga horaria de 170 hs. Luego de cursar y superar con éxito los niveles 1 y 2 respectivamente, las 300 mejores cursantes accedieron al nivel final.El programa se desarrolló entre junio de 2021 y junio de 2022.Tras haber superado exitosamente los tres niveles, cuento con la Certificación de  la Universidad de Alcalá. ",
        diploma: "https://github.com/noedenisel/LeWagon/blob/main/Certificados%20BJNoelia%20Denise%20Lombardo.report.pdf"
    },
 


 
]
