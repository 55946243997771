import React from 'react'

import styles from "./about.module.css"

export default function aboutMe() {
  return (
    <div className={styles.aboutContainer}>
      <h1 className={styles.aboutTitle}>About Me</h1>
        <div className={styles.infoContainer}>
          <p className={styles.textContainer}>Alérgica a las dietas, a la gente mala, a los estereotipos y a los ácaros. Defensora de lo auténtico y odiadora de todo lo que la gente define como “normal”.</p>
          <p className={styles.textContainer}>Mi sentido político y de justicia social da asco al tipo de gente que a mí me da asco también. Algunas veces las formas que tengo de expresarme asusta, las otras tambien.</p>
          <p className={styles.textContainer}>Prefiero que se me odie por franca a que se me ame por lo que no soy.</p>
          <p className={styles.textContainer}>Me gusta resolver problemas y lo hago muy bien: con códigos legales, con machine code o con mis códigos de vida.</p>
          <p className={styles.textContainer}>Me inicié como FullStack dev en la tercera edición del programa Samsung DesarrolladorAs y egrese del Bootcamp Soy Henry donde adquirí nuevas skills y encontré mi perfil front end como UI developer. Me especialicé como FrontEnd en Alura, y actualmente me encuentro realizando el curso de diseño UX/UI en Codo a Codo</p>
          <p className={styles.textContainer}>Me gusta diseñar: webs, interiores, muebles, y la vida de la gente. </p>
          <p className={styles.textContainer}>Abogada especializada en género, en mi trabajo he asistido a familias que han atravesado delitos de los mas crueles: abuso sexuales de NNyA, femicidios, entre otros. Ello me ha formado en empatía y escucha activa hacia la persona a la que tengo enfrente para poder gestionar y atender sus necesidades de la manera mas eficaz posible implicándolos mínimamente en el proceso en que sean parte. En la vida diaria ello se traduce en una excelente capacidad de resolución de conflictos y en la posibilidad de trabajar bajo presión y con entornos hostiles.</p>
          <p className={styles.textContainer}>Tengo nivel B1 de Inglés y A2 de Italiano y Arábe Clásico. También tengo lecto-comprensión de Catalán y Francés.</p>
          <p className={styles.textContainer}>Estudiante empedernida, emprendedora por aburrimiento, proactiva y autoexigente, tengo experiencia en atención al publico, manejo de equipo, ventas, organización de agenda y bastante más.</p>
        </div>
       </div>
  )
}



