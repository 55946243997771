import React from 'react'
import { Link, useLocation} from "react-router-dom"
import {IoPersonOutline} from "react-icons"


import styles from "./navBar.module.css"

import index from "../../img/home.png"
import me from "../../img/user.png"
import code from "../../img/code.png"
import education from "../../img/mortarboard.png"
import contactMe from "../../img/contact-us.png"

export default function NavBar() {

    const location = useLocation()
  return (

        <div className={styles.navBar}>
        {/* Link de home */}
                {
                    location.pathname !== "/" &&
                        <Link to = "/"> <img src={index} alt='home'/> </Link> 
                }

        {/* Links en home */}
            {
                location.pathname === "/" &&
                <Link to="/about"><img src={me} alt="about" /></Link>
                    
            }
            {
                location.pathname === "/" &&
                    <Link to="/projects"><img src={code} alt="projects" /></Link>
                    
            }

            {
                location.pathname === "/" &&
                    <Link to="/education"><img src={education} alt="education" /></Link>
                    
            }
            {
                location.pathname === "/" &&
                    <Link to="/contactme"><img src={contactMe} alt="contactMe" /></Link>
                    
            }

            {/* Links en AboutMe */}

            {
                location.pathname === "/about" &&
                    <Link to="/projects"><img src={code} alt="projects" /></Link>
                    
            }
            {
                location.pathname === "/about" &&
                    <Link to="/education"><img src={education} alt="education" /></Link>
                    
            }
            {
                location.pathname === "/about" &&
                    <Link to="/contactme"><img src={contactMe} alt="contactMe" /></Link>
                    
            }

            {/* Links en projects */}


            {
                location.pathname === "/projects" &&
                    <Link to="/about"><img src={me} alt="about" /></Link>
                    
            }
            {
                location.pathname === "/projects" &&
                    <Link to="/education"><img src={education} alt="education" /></Link>
                    
            }
            {
                location.pathname === "/projects" &&
                    <Link to="/contactme"><img src={contactMe} alt="contactMe" /></Link>
                    
            }


            {/* Links en education */}


            {
                location.pathname === "/education" &&
                <Link to="/about"><img src={me} alt="about" /></Link>
                    
            }
            {
                location.pathname === "/education" &&
                    <Link to="/projects"><img src={code} alt="projects" /></Link>
                    
            }
            {
                location.pathname === "/education" &&
                <Link to="/contactme"><img src={contactMe} alt="contactMe" /></Link>
                    
            }

            {/* Links en contactMe */}


            {
                location.pathname === "/contactme" &&
                    <Link to="/about"><img src={me} alt="about" /></Link>
                    
            }

            {
                location.pathname === "/contactme" &&
                    <Link to="/projects"><img src={code} alt="projects" /></Link>
                    
            }

            {
                location.pathname === "/contactme" &&
                    <Link to="/education"><img src={education} alt="education" /></Link>
                    
            }


          
           
        </div>
  )
}

