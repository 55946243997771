import React from 'react'
import { Link, useLocation} from "react-router-dom"

import styles from "./footer.module.css"

import me from "../../img/user.png"
import code from "../../img/code.png"
import education from "../../img/mortarboard.png"
import contactMe from "../../img/contact-us.png"

export default function Footer() {

    const location = useLocation()
  return (

        <div className={styles.footer}>
      
            <p className={styles.infoFooter}>Created & design by myself with love. V1.1 October 2023</p>

  
        </div>
  )
}

