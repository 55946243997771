import React , { useState }from 'react'
import Card from '../../components/card/card'
import { FiChevronDown } from 'react-icons/fi'; // Importa el ícono de flecha

import { projects } from '../../components/projects'

import styles from "./proyects.module.css"

export default function Portfolio() {
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const [tipoMenuOpen, setTipoMenuOpen] = useState(false);
  // const [lenguajeMenuOpen, setLenguajeMenuOpen] = useState(false);
  const [frameworkMenuOpen, setFrameworkMenuOpen] = useState(false);
  const [styleMenuOpen, setStyleMenuOpen] = useState(false);


  //category
  const [selectedCategories, setSelectedCategories] = useState([]);
  const allCategories = [...new Set(projects.flatMap((project) => project.category))];
  const categories = [...allCategories];

  const handleCategoryCheckboxChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

    //tipo
    const [selectedTipos, setSelectedTipos] = useState([]);
    const allTipos = [...new Set(projects.flatMap((project) => project.tipo))];
    const tipos = [...allTipos];
  
    const handleTipoCheckboxChange = (tipo) => {
      if (selectedTipos.includes(tipo)) {
        setSelectedTipos(selectedTipos.filter((t) => t !== tipo));
      } else {
        setSelectedTipos([...selectedTipos, tipo]);
      }
    };


  // //lenguaje:
  // const [selectedLenguajes, setSelectedLenguajes] = useState([]);
  // const allLenguajes = [...new Set(projects.flatMap((project) => project.lenguaje))];
  // const Lenguajes = [...allLenguajes];

  // const handleTechCheckboxChange = (lenguaje) => {
  //   if (selectedLenguajes.includes(lenguaje)) {
  //     setSelectedLenguajes(selectedLenguajes.filter((t) => t !== lenguaje));
  //   } else {
  //     setSelectedLenguajes([...selectedLenguajes, lenguaje]);
  //   }
  // };

  //frameworks:
  const [selectedFrameworks, setSelectedFrameworks] = useState([]);
  const allFrameworks = [...new Set(projects.flatMap((project) => project.frameworks))];
  const Frameworks = [...allFrameworks];

  const handleFrameworkCheckboxChange = (framework) => {
    if (selectedFrameworks.includes(framework)) {
      setSelectedFrameworks(selectedFrameworks.filter((f) => f !== framework));
    } else {
      setSelectedFrameworks([...selectedFrameworks, framework]);
    }
  };

    //styles:
    const [selectedStyles, setSelectedStyles] = useState([]);
    const allStyles = [...new Set(projects.flatMap((project) => project.styles))];
    const Styles = [...allStyles];
  
    const handleStylesCheckboxChange = (style) => {
      if (selectedStyles.includes(style)) {
        setSelectedStyles(selectedStyles.filter((s) => s !== style));
      } else {
        setSelectedStyles([...selectedStyles, style]);
      }
    };


    const toggleCategoryMenu = () => {
      setCategoryMenuOpen(!categoryMenuOpen);
    };
  
    const toggleTipoMenu = () => {
      setTipoMenuOpen(!tipoMenuOpen);
    };
  
    // const toggleLenguajeMenu = () => {
    //   setLenguajeMenuOpen(!lenguajeMenuOpen);
    // };
  
    const toggleFrameworkMenu = () => {
      setFrameworkMenuOpen(!frameworkMenuOpen);
    };
  
    const toggleStyleMenu = () => {
      setStyleMenuOpen(!styleMenuOpen);
    };
  

    const filteredProjects = projects.filter((project) => {
      const categoryMatch = selectedCategories.length === 0 || selectedCategories.some((category) => project.category.includes(category));
      const tipoMatch = selectedTipos.length === 0 || selectedTipos.some((tipo) => project.tipo.includes(tipo));
      // const lenguajeMatch = selectedLenguajes.length === 0 || selectedLenguajes.some((lenguaje) => project.lenguaje.includes(lenguaje));
      const frameworkMatch = selectedFrameworks.length === 0 || selectedFrameworks.some((framework) => project.frameworks.includes(framework));
      const styleMatch = selectedStyles.length === 0 || selectedStyles.some((style) => project.styles.includes(style));
    
      return categoryMatch && tipoMatch && frameworkMatch && styleMatch;
    });
    
  return (
    <div className={styles.cardsContainer}>
      <h2 className={styles.portfolioTitle}>Mi portfolio</h2>
        <div className={styles.infoContainer}>
          <p className={styles.textContainer}>Una selección de mis mejores trabajos tanto FrontEnd como FullStack.</p>
          <p className={styles.textContainer}>En mi github podrás encontrar +50 proyectos con diferentes tecnologias tales como HTML | CSS (flexbox – grid – boostrap- sass – atomic & responsive design) | JS | | REACT JS (Material UI – Formik- React boostrap – redux toolkit) | TS | ANGULAR </p>
          <p className={styles.textContainer}>y en el  BACKEND: NODE | EXPRESS PostreSQL | SQL</p>
        </div>

    
  
          <div className={styles.filterContainers}>
          
            <div className={styles.filterControls}>
            {/* category*/}
            <span onClick={toggleCategoryMenu} className={styles.filterButton}>
              Category <FiChevronDown className={styles.menu}/>
            </span>
              {categoryMenuOpen && (
                <div className={styles.checkboxGroup}>
                  {categories.map((category) => (
                    <div key={category}>
                      <input
                        type="checkbox"
                        id={category}
                        value={category}
                        checked={selectedCategories.includes(category)}
                        onChange={() => handleCategoryCheckboxChange(category)}
                      />
                      <label htmlFor={category}>{category}</label>
                    </div>
                  ))}
                </div>
              )}
              </div>
          

            <div className={styles.filterControls}>
            {/* tipo*/}
            <span onClick={toggleTipoMenu} className={styles.filterButton}>
              Tipo <FiChevronDown className={styles.menu}/>
            </span>
              {tipoMenuOpen && (
                <div className={styles.checkboxGroup}>
                  {tipos.map((tipo) => (
                    <div key={tipo}>
                      <input
                        type="checkbox"
                        id={tipo}
                        value={tipo}
                        checked={selectedTipos.includes(tipo)}
                        onChange={() => handleTipoCheckboxChange(tipo)}
                      />
                      <label htmlFor={tipo}>{tipo}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            {/* languaje */}
            {/* <div className={styles.filterControls}>
           
            <span onClick={toggleLenguajeMenu} className={styles.filterButton}>
              Lenguajes <FiChevronDown className={styles.menu}/>
            </span>
              {lenguajeMenuOpen && (
                <div className={styles.checkboxGroup}>
                  {Lenguajes.map((lenguaje) => (
                    <div key={lenguaje}>
                      <input
                        type="checkbox"
                        id={lenguaje}
                        value={lenguaje}
                        checked={selectedLenguajes.includes(lenguaje)}
                        onChange={() => handleTechCheckboxChange(lenguaje)}
                      />
                      <label htmlFor={lenguaje}>{lenguaje}</label>
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            <div className={styles.filterControls}>
            {/* frameworks */}
            <span onClick={toggleFrameworkMenu} className={styles.filterButton}>
              Frameworks <FiChevronDown className={styles.menu}/>
            </span>
              {frameworkMenuOpen && (
                <div className={styles.checkboxGroup}>
                  {Frameworks.map((framework) => (
                    <div key={framework}>
                      <input
                        type="checkbox"
                        id={framework}
                        value={framework}
                        checked={selectedFrameworks.includes(framework)}
                       onChange={() => handleFrameworkCheckboxChange(framework)} 
                    
                      />
                      <label htmlFor={framework}>{framework}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={styles.filterControls}>
            {/* styles */}
            <span onClick={toggleStyleMenu} className={styles.filterButton}>
              Styles <FiChevronDown className={styles.menu}/>
            </span>
              {styleMenuOpen && (
                <div className={styles.checkboxGroup}>
                  {Styles.map((style) => (
                    <div key={style}>
                      <input
                        type="checkbox"
                        id={style}
                        value={style}
                        checked={selectedStyles.includes(style)}
                        onChange={() => handleStylesCheckboxChange(style)}

                      />
                      <label htmlFor={style}>{style}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>

      
      </div>
        


        <div className={styles.cardContainer}>
          {filteredProjects.map((project, index) => (
            <Card
              key={index}
              title= {project.title}
              category= {project.category}
              tipo={project.tipo}
              lenguaje= {project.lenguaje}
              frameworks={project.frameworks}
              styles={project.styles}
              entorno={project.entorno}
              image={project.image}
              name={project.title}
              resume={project.resume}
              github= {project.github}
              demo={project.demo}
            />
          ))}
        </div>
      </div>
    );
}

